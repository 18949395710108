<template>
  <Dialog>
    <AddressSuggestions
      :formkit-address-fields="{
        street: 'registrationStreet',
        number: 'registrationHouseNumber',
        zipcode: 'registrationZip',
        city: 'registrationCity',
        name1: 'name1',
      }"
    />
    <Form
      id="edit-profile-form"
      submit-label="user.account.profile.editDialog.submit"
      :is-loading="isLoading"
      @submit="onSubmit"
      @cancel="dialogStore.closeDialog()"
    >
      <template #errors>
        <div
          v-if="errors.length && errors.includes(ErrorIdent.UNKNOWN_ERROR)"
          class="mb-md"
        >
          <UnknownError />
        </div>
      </template>
      <template #default>
        <TextInput
          id="name1"
          v-model="name1"
          name="name1"
          :label="`${t(label)} 1`"
          :placeholder="`${t(label)} 1`"
          :required-message="name1RequiredErrorMsg"
          :info="t('registration.info.customerType', { LABEL: t(label) })"
          :maxlength="40"
          autofocus
        />
        <MultiSelect
          id="name2"
          :initial-value="name2"
          name="name2"
          :label="`${t(label)} 2`"
          :options="name2Suggestions"
          :required="false"
          placeholder="user.account.profile.editDialog.name2Placeholder"
          :maxlength="40"
        />
        <Address
          v-model:street="street"
          v-model:number="houseNumber"
          v-model:zip="zip"
          v-model:city="city"
          validate-packing-station
        />
        <Dropdown
          id="registrationOfficeCountry"
          v-model="selectedCountry"
          name="country"
          label="registration.billingAddress.country"
          :options="countryOptions"
          :required="true"
          :validation-message="
            t('registration.emptyError.billingAddress.country')
          "
          :custom-select="CustomSelectCountry"
          :disabled="countryOptions.length <= 1"
          :force-info="true"
        >
          <template #info>
            {{ t('operatingCountries.info') }}
          </template>
        </Dropdown>
        <Checkbox
          v-model="isDefaultBillingAddress"
          class="pt-sm mb-sm"
          label="user.account.addresses.newEditDialog.isDefaultBillingAddress"
          name="defaultBillingAddress"
          :disabled="defaultBillingDisabled"
        />
        <Checkbox
          v-model="isDefaultShippingAddress"
          class="mb-sm"
          label="user.account.addresses.newEditDialog.isDefaultShippingAddress"
          name="defaultShippingAddress"
          :disabled="defaultShippingDisabled"
        />
        <AddressDrugHint v-if="showAddressDrugHint" class="mt-sm" />
      </template>
    </Form>
    <AddressValidationChoice
      :address-validation-data="validationResult"
      :open="needsManualCheck"
      :highlight-suggestions="true"
      @address-chosen="(val) => handleFillAddress(val)"
    />
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { useDialogStore } from '@/stores/useDialogStore';
import { ErrorIdent } from '@/@types/errorIdents';
import Form from '@/components/formFields/localDialogForm/localDialogForm.vue';
import UnknownError from '~/components/formFields/errors/unknown/unknownError.vue';
import MultiSelect from '@/components/formFields/multiSelect.vue';
import { useNewEditAddress } from '../useNewEditAddress';
import Address from '@/components/formFields/addressRows/addressRows.vue';
import CustomSelectCountry from '@/components/formFields/components/customSelects/customSelectCountry.vue';
import AddressValidationChoice from '@/components/formTools/address/validation/addressChoice.vue';
import AddressSuggestions from '@/components/formTools/address/suggestions/addressSuggestions.vue';
import { Checkbox, Dropdown, TextInput } from '@/complib';
import AddressDrugHint from '@/components/shop/atoms/addressDrugHint.vue';

const { t } = useTrans();
const {
  onSubmit,
  errors,
  isLoading,
  countryOptions,
  selectedCountry,
  label,
  name1RequiredErrorMsg,
  name1,
  name2,
  street,
  houseNumber,
  zip,
  city,
  isDefaultBillingAddress,
  isDefaultShippingAddress,
  defaultBillingDisabled,
  defaultShippingDisabled,
  name2Suggestions,
  showAddressDrugHint,
  needsManualCheck,
  validationResult,
  handleFillAddress,
} = await useNewEditAddress();

const dialogStore = useDialogStore();
</script>
